
















































































































import Vue from 'vue';
import dayjs, { Dayjs } from 'dayjs';
import { Location, CreateQuoteRequestRequest } from '@/services/mytako';
import segment from '@/services/segment';
import log from '@/services/log';
import AddressAutoComplete from '@/components/AddressAutoComplete.vue';
import DateTime from '@/components/DateTime.vue';
import Map from '@/components/Map.vue';
import store from '@/services/store';

export default Vue.extend({
    metaInfo: {
        title: 'Search',
    },

    components: { AddressAutoComplete, DateTime, Map },

    data() {
        return {
            pickupLocation: null as Location | null,
            destinationLocation: null as Location | null,
            asap: 'true',
            pickupAt: undefined as Dayjs | undefined,
            passengersCount: 1,
            passengersSelect: [1, 2, 3, 4, 5, 6, 7, 8],
            luggagesCount: 0,
            luggagesSelect: [0, 1, 2, 3, 4, 5, 6, 7, 8],
        };
    },

    mounted() {
        log.warn('***** PAGE-1');
        segment.page('request');
        this.$vuetify.goTo(0);

        const { pickupAt } = store.quoteRequest.get() as CreateQuoteRequestRequest;
        if (pickupAt) {
            this.pickupAt = dayjs(pickupAt);
            this.asap = 'false';
        }
    },

    methods: {
        onChangePickupLocation(location: Location) {
            this.pickupLocation = location;
        },

        onChangeDestinationLocation(location: Location) {
            this.destinationLocation = location;
        },

        onChangeDateTime(dateTime: Dayjs) {
            log.warn('[Request] onChangeDateTime >', dateTime?.toString());
            this.pickupAt = dateTime;
        },

        submitQuotesSearch() {
            if (this.pickupLocation == null || this.destinationLocation == null) {
                return;
            }
            const quoteRequest: CreateQuoteRequestRequest = {
                pickupLocation: this.pickupLocation,
                destinationLocation: this.destinationLocation,
                passengerCount: this.passengersCount,
                luggageCount: this.luggagesCount,
                pickupAt: this.asap === 'true' ? undefined : this.pickupAt?.format(),
            };
            log.warn('quoteRequest', quoteRequest);
            segment.track('request/search', quoteRequest);
            store.quoteRequest.set(quoteRequest);
            this.$router.push('/result');
        },
    },
});
