



































import Vue from 'vue';
import mytako from '@/services/mytako';
import log from '@/services/log';

export default Vue.extend({
    name: 'Map',

    props: ['width', 'height', 'pickupLocation', 'destinationLocation', 'hideInfo', 'defaultSrc'],

    data() {
        return {
            info: '',
            url: '',
        };
    },

    watch: {
        pickupLocation() {
            log.debug(`map:watch:pickupLocation`);
            this.refresh();
        },
        destinationLocation() {
            log.debug(`map:watch:destinationLocation`);
            this.refresh();
        },
    },

    mounted() {
        log.debug(`map:mounted`);
        this.refresh();
    },

    methods: {
        async refresh() {
            log.debug('map:refresh');

            this.url = await mytako.api.locations.getLocationsMapUrl({
                ...(this.pickupLocation ? { pickupLocation: this.pickupLocation } : undefined),
                ...(this.destinationLocation ? { destinationLocation: this.destinationLocation } : undefined),
                width: this.width,
                height: this.height,
            });

            // route info
            if (this.hideInfo || this.pickupLocation == null || this.destinationLocation == null) {
                return;
            }
            try {
                log.debug('pickupLocation', this.pickupLocation);
                log.debug('destinationLocation', this.destinationLocation);
                const routeInfo = await mytako.api.locations.getRouteInfo({
                    pickupLocation: this.pickupLocation,
                    destinationLocation: this.destinationLocation,
                });
                if (routeInfo.distance !== -1 && routeInfo.duration !== -1) {
                    const routeInfoFormatted = {
                        distance: routeInfo.distance !== -1 ? `${routeInfo.distance ? routeInfo.distance.toFixed(2) : ''}${routeInfo.distanceUnit}` : 'n.a',
                        duration:
                            routeInfo.duration !== -1
                                ? `${routeInfo.duration.toFixed(0)}${routeInfo.durationUnit === 'minute' ? 'mn' : routeInfo.durationUnit}`
                                : 'n.a',
                    };
                    this.info = this.$t('SHARED.DISTANCE_AND_DURATION', routeInfoFormatted) as string;
                } else {
                    this.info = '';
                }
            } catch (error: any) {
                log.debug(error.message);
                this.info = '';
            }
        },
    },
});
